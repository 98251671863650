import React, { useState } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import LinkWrapper from "../reusable-components/link-wrapper"
import SubmitButton from "../reusable-components/buttons/submit-button"

import { slugify } from "../../lib/utils"

const ProjectBox = ({
  projectIndex,
  fluidFeaturedImage,
  category,
  name,
  shortDescription,
}) => {
  const [showCard, setShowCard] = useState(false)
  const handleClick = (e) => {
    e.preventDefault()
    setShowCard(true)
  }
  return (
    <ProjectBoxContainer key={projectIndex}>
      <StyledBackgroundImage fluid={fluidFeaturedImage} id="main-content">
        <ProjectBoxStyling>
          <h5 dangerouslySetInnerHTML={{ __html: category }} />
          <h4 dangerouslySetInnerHTML={{ __html: name }} />
        </ProjectBoxStyling>
      </StyledBackgroundImage>
      <ProjectBoxHoverContent
        id="hover-content"
        className={showCard ? "show-content" : "hide-content"}
        onClick={handleClick}
      >
        <h4 dangerouslySetInnerHTML={{ __html: name }} />
        <div dangerouslySetInnerHTML={{ __html: shortDescription }} />

        <StyledButton>
          <LinkWrapper to={`/completed-projects/${slugify(name)}/`}>
            <SubmitButton text="View Project" />
          </LinkWrapper>
        </StyledButton>
      </ProjectBoxHoverContent>
    </ProjectBoxContainer>
  )
}

export default ProjectBox

// ===============
//     STYLES
// ===============
const ProjectBoxContainer = styled.div`
  :hover #hover-content {
    display: block;
  }
  :hover #main-content {
    display: none;
  }
  position: relative;
  margin-top: 10px;
`

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 400px;
  width: 400px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
    height: 100vw;
  }
`
const ProjectBoxStyling = styled.div`
  padding: 30px;
  margin-top: 10px;
  position: absolute;
  opacity: 1 !important;
  width: 100%;
  background: inherit;
  background-attachment: fixed;
  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 90%;
    height: 80%;
    background: inherit;
    background-attachment: fixed;
    -webkit-filter: blur(40px);
    filter: blur(40px);
  }
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 80%;
    filter: blur(40px);
    background: ${({ theme }) => theme.colors.white}ee;
  }
  h5 {
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
    z-index: 10;
    position: relative;
  }
  h4 {
    padding-bottom: 10px;
    z-index: 10;
    position: relative;
  }
`
const ProjectBoxHoverContent = styled.div`
  height: 400px;
  width: 400px;
  padding: 30px;
  display: none;
  background-color: white;
  overflow: hidden;
  h4 {
    padding-bottom: 10px;
  }
  > div {
    max-height: 160px;
    overflow: hidden;
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      max-height: 40vw;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
    height: 100vw;
    padding: 20px;
  }
`
const StyledButton = styled.div`
  padding: 20px 0 0;
`
