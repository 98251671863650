import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/header/seo"
import ProjectBox from "../components/projects-page/project-box"

import { getProperty } from "../lib/utils"

const CompletedProjectsPage = ({ pathContext, location }) => {
  const projectsPageQueryData = useStaticQuery(PROJECTS_PAGE_QUERY)
  const title = getProperty(projectsPageQueryData, ["wpPage", "title"], "")
  const yoast = getProperty(projectsPageQueryData, ["seaPage", "seo"], {})
  const projects = getProperty(
    projectsPageQueryData,
    ["wpPage", "acf_completed_projects", "projects"],
    []
  )

  return (
    <Layout pathContext={pathContext} location={location}>
      <SEO
        title={getProperty(
          projectsPageQueryData,
          ["seaPage", "seo", "title"],
          ""
        )}
        location={location}
        yoast={yoast}
      />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <ProjectsSectionStyling>
        {projects.map((project, project_i) => {
          const name = getProperty(project, ["projectTitle"], "")
          const category = getProperty(project, ["projectCategory"], "")
          const featuredImage = getProperty(
            project,
            ["projectFeaturedImage", "localFile", "childImageSharp", "fluid"],
            ""
          )
          const shortDescription = getProperty(
            project,
            ["projectShortDescription"],
            ""
          )
          return !name ? null : (
            <ProjectBox
              key={project_i}
              projectIndex={project_i}
              fluidFeaturedImage={featuredImage}
              category={category}
              name={name}
              shortDescription={shortDescription}
            />
          )
        })}
      </ProjectsSectionStyling>
    </Layout>
  )
}

export default CompletedProjectsPage

// ===============
//     STYLES
// ===============
const ProjectsSectionStyling = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-gap: 20px;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: repeat(2, 400px);
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 400px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
  }
`

// ===============
//      QUERY
// ===============
const PROJECTS_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "completed-projects" }) {
      title
      acf_completed_projects {
        projects {
          projectCategory
          projectTitle
          projectShortDescription
          projectFeaturedImage {
            localFile {
              childImageSharp {
                fluid(quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          projectFullDescription
          projectGallery {
            projectGalleryImage {
              localFile {
                childImageSharp {
                  fluid(quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    seaPage(slug: { eq: "completed-projects" }) {
      seo {
        og_description
        description
        meta_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_description
        twitter_creator
        twitter_title
      }
    }
  }
`
